// import { Redirect } from "react-router-dom";
import Customize from "../Components/Customize";
import Mismatched from "../Components/Mismatched";
import Login from "../pages/Authentication/Login";
import ContactUs from "../pages/ContactUs";
import Dashboard from "../pages/Dashboard";
import Index from "../pages/HomePage/index";
import { LoginMarket } from "../pages/LoginMarket";
import { LoginMyPlan } from "../pages/LoginMyPlan";
import Logout from "../pages/Logout";
import PlanInsight from "../pages/PlanInsight";
import { StateInsight } from "../pages/StateInsight";

const authProtectedRoutes = [
  { path: "/market", component: LoginMarket },
  { path: "/my-plan", component: LoginMyPlan },
  { path: "/utility-insight", component: StateInsight },
  { path: "/customize", component: Customize },
  { path: "/mismatched", component: Mismatched },
  { path: "/dashboard", component: Dashboard },
  { path: "/plan-insight", component: PlanInsight },

  // this route should be at the end of all other routes
  // {
  //     path: '/',
  //     exact: true,
  //     component: () => <Redirect to="/" />,
  // }
];

const publicRoutes = [
  { path: "/", component: Index },
  { path: "/contact-us", component: ContactUs },
];

const otherRoutes = [
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
];

export { authProtectedRoutes, publicRoutes, otherRoutes };
