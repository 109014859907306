import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import "../styles/market.css";
import {
  fetchLoginLowestPrice,
  fetchLowestPrice,
  fetchTerms,
} from "../api/market";
import { Context } from "../pages/LoginMarket";
import {
  Card,
  CardBody,
  CardHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
} from "reactstrap";
import { DataWithColorSeriesTypes, DataPointTypes } from "../types";
// import { errornotify } from "./Message";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectUtility } from "../store/utility/selector";
import { PriceComparisionChart } from "./Charts/PriceComparisionChart";
import UsageBtnGroup from "./UsageBtnGroup";
import { selectLocation } from "../store/location/selector";
import { Typography } from "@mui/material";
import { PriceBarChart } from "./Charts/PriceBarChart";
import DataTable from "react-data-table-component";
// import AiSummary from "./AiSummary";

interface searchOptionObj {
  value: any;
  label: any;
}

const startDate = moment().subtract(1, "months").format("YYYY-MM-DD");
const endDate = moment().format("YYYY-MM-DD");

const toChartsDataLowest = (inputData: any, outputData: any) => {
  if (inputData) {
    return inputData.map((item: any) => {
      if (item.date && +item.lowest_price !== 0) {
        return outputData.push({ x: item.date, y: +item.lowest_price });
      }
    });
  } else {
    return (outputData = []);
  }
};

const MarketLowestRateTx = () => {
  const { provider, allProvider } = useContext(Context);
  const [term, setTerm] = useState("");
  const [termList, setTermList] = useState([]);
  const [compareProvider, setCompareProvider] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading12Month, setIsLoading12Month] = useState(false);
  const currentLocation = useSelector(selectLocation);
  const currentUtility = useSelector(selectUtility);

  const [filterValue, setFilterValue] = useState({
    price: 500,
    date: `${startDate}&${endDate}`,
    term: "",
  });
  const [lowestData, setLowestData] = useState<DataWithColorSeriesTypes[]>([]);
  const [last12MonthData, setLast12MonthData] = useState<any>([]);

  const [providerList, setProviderList] = useState(["", provider.provider_id]);

  const [openTerms, setOpenTerms] = useState(false);

  const [searchProviderList, setSearchProviderList] = useState<
    Array<searchOptionObj>
  >([]);
  const [dataString, setDataString] = useState("");

  const getLowestPrice = async () => {
    setIsLoading(true);

    const promise = providerList.map((item) => {
      if (+item) {
        return fetchLoginLowestPrice({
          utility_id: currentUtility.utility_id,
          ...filterValue,
          provider_id: +item,
        });
      } else {
        return fetchLoginLowestPrice({
          utility_id: currentUtility.utility_id,
          ...filterValue,
        });
      }
    });
    const res = await Promise.all(promise);
    const resData: any = res.map((item, index) => {
      if (item.status === 1) {
        return item.response.lowest_price;
      } else {
        // errornotify(item.message || `Error trend[${index + 1}]`);
        return null;
      }
    });

    let avgData: DataPointTypes[] = [];
    let providerData: DataPointTypes[] = [];
    toChartsDataLowest(resData[0], avgData);
    toChartsDataLowest(resData[1], providerData);
    setLowestData([
      {
        id: `${provider ? provider.provider_name : ""}`,
        data: providerData,
        color: "hsl(207,54.0%,46.9%)",
      },
      {
        id: "Market Price",
        data: avgData,
        color: "hsl(118,40.6%,48.8%)",
      },
    ]);

    let _dataString = "";
    res.forEach((item) => {
      if (item.response) {
        const { response } = item;
        _dataString += `
            ##${
              response?.provider?.provider_name || "Market"
            } Lowest Rate Change
            ${JSON.stringify(response.lowest_price)}
        `;
      }
    });
    setDataString((prev) => prev + _dataString);

    setIsLoading(false);
  };

  const getLast12MonthsData = async () => {
    setIsLoading12Month(true);
    const low_res = await fetchLowestPrice({
      utility_id: currentUtility.utility_id,
      price: filterValue.price,
      term: filterValue.term,
    });
    setIsLoading12Month(false);
    if (low_res?.status === 1) {
      const { lowest_price } = low_res?.response || {};

      setLast12MonthData(
        lowest_price?.map((item: any) => ({
          date: moment(item.date, "MM/DD/YYYY").format("YYYY-MM-DD"),
          value: Number(item.lowest_price),
        }))?.slice(-12) || []
      );
    }
  };

  useEffect(() => {
    if (+provider.provider_id && allProvider.length && currentUtility) {
      setDataString("");
      getLowestPrice();
      getLast12MonthsData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue, providerList, currentUtility]);

  useEffect(() => {
    const getTerms = async () => {
      const res = await fetchTerms(currentUtility.utility_id);
      if (res.status !== 1) {
        return;
      }
      setTermList(res.response);
    };

    if (currentUtility) {
      getTerms();
    }
  }, [currentUtility]);

  useEffect(() => {
    if (+provider.provider_id && allProvider.length) {
      let searchList = allProvider.map((item: any) => {
        return { value: item.provider_id, label: item.provider_name };
      });
      setProviderList(["", provider.provider_id]);
      setSearchProviderList(searchList);
    } else {
      return;
    }
  }, [provider, allProvider]);

  useEffect(() => {
    if (compareProvider) {
      changeCompare(compareProvider);
    } else {
      setLowestData((datas) => {
        let arr = datas.slice(0);
        arr.splice(0, 1);
        return arr;
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareProvider, filterValue]);

  const changeUsage = (val: string) => {
    setFilterValue({
      ...filterValue,
      price: +val,
    });
  };

  const changeTerm = (val: any) => {
    setTerm(val);
    setFilterValue({
      ...filterValue,
      term: val,
    });
  };

  const compareFun = (datas: any, val: any, id: any) => {
    let arr = datas.slice(0);
    if (arr.length === 2) {
      arr.unshift({
        id: `${id.label ? id.label : ""}`,
        data: val,
        color: "hsl(359,79.5%,49.8%)",
      });
    } else {
      arr = datas.map((item: any, index: any) => {
        if (index === 0) {
          return {
            id: `${id.label ? id.label : ""}`,
            data: val,
            color: "hsl(359,79.5%,49.8%)",
          };
        } else {
          return item;
        }
      });
    }
    return arr;
  };

  const changeCompare = async (id: any) => {
    setIsLoading(true);
    const low_res = await fetchLoginLowestPrice({
      utility_id: currentUtility.utility_id,
      ...filterValue,
      provider_id: id.value,
    });
    if (low_res.status === 1) {
      let lowCompare: any = [];
      toChartsDataLowest(low_res.response.lowest_price, lowCompare);
      setLowestData((datas) => compareFun(datas, lowCompare, id));
    } else {
      // errornotify(low_res.message || `Error trend[lowest]`);
    }
    setIsLoading(false);
  };

  const compareWith = (obj: any) => {
    setCompareProvider(obj);
  };

  return (
    <Card style={{ minHeight: "600px" }}>
      <CardHeader>
        <h4 className="card-title mb-0 flex-grow-1">Market Lowest Rate</h4>
        {currentLocation && currentUtility && (
          <Typography
            variant="subtitle2"
            className="text-muted"
          >{`${currentLocation.state_name} - ${currentUtility.utility_name}`}</Typography>
        )}
      </CardHeader>
      {/* <AiSummary data={dataString} /> */}
      <CardBody>
        <React.Fragment>
          <div>
            <div className="mb-3">
              <UsageBtnGroup
                onChangeValue={(val: string) => changeUsage(val)}
                isLoading={isLoading}
              />
            </div>
            <Dropdown
              isOpen={openTerms}
              toggle={() => setOpenTerms(!openTerms)}
            >
              <DropdownToggle tag="button" className="btn btn-light">
                {term ? `${term} Month` : "All Term"}{" "}
                <i className="mdi mdi-chevron-down"></i>
              </DropdownToggle>
              <DropdownMenu style={{ overflow: "scroll", height: "20rem" }}>
                <DropdownItem key={"allterm"} onClick={() => changeTerm("")}>
                  All Term
                </DropdownItem>
                {termList.map((item: any, index: any) => (
                  <DropdownItem key={index} onClick={() => changeTerm(item)}>
                    {item} Month
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>

          <div
            style={{ width: "80%", marginTop: "10px" }}
            className="mobile-compareSelect"
          >
            <Label
              htmlFor="aria-example-input"
              className="form-label text-muted"
              style={{ fontWeight: "400" }}
            >
              Compare with
            </Label>
            <Select
              defaultValue={searchProviderList[0]}
              value={compareProvider}
              isClearable
              onChange={(e) => compareWith(e)}
              options={searchProviderList}
            />
          </div>
          <PriceBarChart
            title="Average Rate (Last 12 months)"
            data={last12MonthData}
            isLoading={isLoading12Month}
          />

          <div>
            <Label
              htmlFor="aria-example-input"
              className="form-label text-muted"
              style={{ fontWeight: "400" }}
            >
              Compare with
            </Label>
            <div style={{ width: "240px" }}>
              <Select
                defaultValue={searchProviderList[0]}
                value={compareProvider}
                isClearable
                onChange={(e) => compareWith(e)}
                options={searchProviderList}
              />
            </div>
          </div>
          <PriceComparisionChart
            title="Lowest Rate (Last 30 days)"
            data={lowestData}
            isLoading={isLoading}
          />
          {!isLoading && <TrendTable data={lowestData} />}
        </React.Fragment>
      </CardBody>
    </Card>
  );
};

const TrendTable = ({ data }: { data: any }) => {
  const formatTableData = (data: any) => {
    return data.map((item: any) => {
      const formatted: any = { id: item.id };
      item.data.forEach((entry: any) => {
        formatted[entry.x] = entry.y.toString();
      });
      return formatted;
    });
  };

  const tableData = formatTableData(data) || [];

  const frozenColumns = [
    {
      name: <span className="font-weight-bold fs-14">Rate Type</span>,
      selector: (row: any) => row.id,
    },
  ];

  const scrollableColumns = Object.keys(tableData[0] || {})
    .filter((key) => key !== "id")
    .map((dateKey) => ({
      name: dateKey,
      selector: (row: any) => `${row[dateKey]}¢`,
    }));

  if (tableData.length === 0) return null;

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F2F2F2",
      },
    },
  };
  return (
    <div className="combined-table">
      <div className="fronzen-table">
        <DataTable
          columns={frozenColumns}
          data={tableData}
          paginationPerPage={10}
          customStyles={{
            cells: { style: { backgroundColor: "#fafafa" } },
            ...customStyles,
          }}
          pagination={false}
        />
      </div>
      <div className="scrollable-table">
        <DataTable
          columns={scrollableColumns}
          data={tableData}
          paginationPerPage={10}
          customStyles={customStyles}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default MarketLowestRateTx;
