import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { getLatestAvgRate, getTotalNum } from "../../api/home";
import USGeoMap from "./USGeoMapGoogle";
import { RaceChart } from "../../Components/RaceChart2";
import { selectStateList } from "../../store/location/selector";
import { useSelector } from "react-redux";
import ContactForm from "../../Components/ContactForm";

type SelectState = {
  state_id: string;
  state_name: string;
};

const features = [
  {
    icon: "feature_analytics.png",
    text: "Verify price competitiveness vs. key competitors and the market",
  },
  {
    icon: "feature_data_research.png",
    text: "Identify pricing “Sweet Spots” and attractive competitive comparisons",
  },
  {
    icon: "feature_consulting.png",
    text: "Use data to create compelling Sales and Marketing messaging",
  },
  {
    icon: "feature_risk_analytics.png",
    text: "Effectively audits TOS and EFL against posted plan rates",
  },
];

const funcs = [
  "Rate Trending/TDU/Usage/Term/Competitor",
  "Market’s Lowest Rate Plans by TDU",
  "All Market Plans",
  "Market Average Rate by TDU/Usage",
  "My Plan Rank",
  "My Plans Ranked by Rate",
  "Rate % Change by TDU/Usage/Term",
  "More",
];

const Home = () => {
  const stateList = useSelector(selectStateList);
  const [selectedState, setSelectedState] = useState<SelectState | null>(null);
  const [latestAvgRate, setLatestAvgRate] = useState<any>(null);

  const [nums, setNums] = useState({
    total_states: 0,
    total_utilities: 0,
    total_suppliers: 0,
    total_plans: 0,
  });

  useEffect(() => {
    if (stateList?.length > 0) {
      setSelectedState(stateList[0]);
    }
  }, [stateList]);

  useEffect(() => {
    const getNum = async () => {
      getTotalNum().then((res) => {
        if (res?.status === 1) {
          const {
            total_states,
            total_utilities,
            total_suppliers,
            total_plans,
          } = res?.response || {};
          setNums({
            total_states: total_states || 0,
            total_utilities: total_utilities || 0,
            total_suppliers: total_suppliers || 0,
            total_plans: total_plans || 0,
          });
        }
      });
    };

    getNum();
  }, []);

  // useEffect(() => {
  //   if (selectedState) {
  //     getLatestAvgRate(selectedState.state_id).then((res) => {
  //       if (res?.status === 1) {
  //         setLatestAvgRate(res.response);
  //       }
  //     });
  //   }
  // }, [selectedState]);

  return (
    <Box mt={-4}>
      <section className="section mb-0">
        <div
          className="bg-overlay bg-overlay-pattern"
          style={{ opacity: "0.25" }}
        ></div>
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <div className="d-flex flex-column justify-content-center h-100">
                <Typography
                  mb={4}
                  fontSize={{ xs: "2rem", md: "2.6rem" }}
                  fontWeight="bold"
                  lineHeight={1.1}
                >
                  Residential Electricity Market and Plan Data for the Retail
                  Energy Industry
                </Typography>
                <div className="d-flex gap-4">
                  <div>
                    <CountUp
                      start={0}
                      separator=","
                      end={nums.total_states}
                      duration={1}
                      className="display-6 fw-semibold text-secondary"
                    />
                    <p className="text-muted">Total States</p>
                  </div>
                  <div>
                    <CountUp
                      start={0}
                      separator=","
                      end={nums.total_utilities}
                      duration={1}
                      className="display-6 fw-semibold text-secondary"
                    />
                    <p className="text-muted">Total Utilities</p>
                  </div>
                  <div>
                    <CountUp
                      start={0}
                      separator=","
                      end={nums.total_suppliers}
                      duration={1}
                      className="display-6 fw-semibold text-secondary"
                    />
                    <p className="text-muted">Total Suppliers</p>
                  </div>
                  <div>
                    <CountUp
                      start={0}
                      separator=","
                      end={nums.total_plans}
                      duration={1}
                      className="display-6 fw-semibold text-secondary"
                    />
                    <p className="text-muted">Total Plans</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <USGeoMap />
            </Col>
          </Row>

          <div className="d-flex justify-content-center gap-4 my-4">
            <Link to="/contact-us" className="btn btn-outline-secondary">
              Talk To Sales{" "}
              <i className="ri-headphone-line align-middle ms-1"></i>
            </Link>
            <Link to="/contact-us" className="btn btn-secondary">
              Get Started{" "}
              <i className="ri-arrow-right-line align-middle ms-1"></i>
            </Link>
          </div>
        </Container>
      </section>

      <Box py={7}>
        <Container>
          <Box
            px={4}
            py={5}
            borderRadius={4}
            sx={{
              backgroundColor: "#F2F7FA",
            }}
          >
            <Grid container spacing={6}>
              <Grid item xs={12} md={4}>
                <img src="/img/data_store_1.png" width="100%" alt="" />
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                className="d-flex flex-column justify-content-center"
              >
                <Typography fontSize="1.4rem" mb={3} lineHeight={1.2}>
                  PowerRateIndex continually scrapes the internet to gather the
                  most comprehensive, timely and accurate Residential
                  Electricity Plan data available
                </Typography>
                <ul className="list-unstyled text-muted">
                  <li className="mb-1">
                    - All markets and plans scraped at least once a day
                  </li>
                  <li className="mb-1">
                    - Our AI calculates the effective rate for any plan
                    structure including tiered and TOU plans
                  </li>
                  <li>
                    - Continually monitors and updates utilities’
                    Price-to-Compare
                  </li>
                </ul>
              </Grid>
              {/* <Grid item xs={12} md={4}>
                <Typography className="text-muted" fontSize="0.875rem" mb={2}>
                  Offering:
                </Typography>
                <Box sx={{ display: "flex", gap: 1.5, flexWrap: "wrap" }}>
                  {funcs.map((val, index) => (
                    <Paper
                      elevation={0}
                      key={index}
                      sx={{
                        py: 1,
                        px: 1.5,
                        borderRadius: 1,
                        fontSize: "13px",
                        backgroundColor:
                          index === funcs.length - 1 ? "#333" : "#fff",
                        color: index === funcs.length - 1 ? "#fff" : "#333",
                        boxShadow: "0 2px 6px #00000009",
                      }}
                    >
                      {val}
                    </Paper>
                  ))}
                </Box>
              </Grid> */}
            </Grid>
          </Box>
        </Container>
      </Box>

      <Box py={6}>
        <Container>
          <Typography
            textAlign="center"
            mb={5}
            lineHeight={1.2}
            fontWeight="bold"
            fontSize={{ xs: "1.5rem", md: "2rem" }}
            marginX="auto"
            maxWidth={800}
          >
            PowerRateIndex is a critical tool for making effective Pricing,
            Sales and Marketing decisions
          </Typography>
          <Grid container spacing={2}>
            {features.map((feature, index) => (
              <Grid item xs={12} sm={3} key={index}>
                <Paper
                  elevation={3}
                  sx={{
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <img src={`/img/${feature.icon}`} width="50%" alt="" />
                  <Typography variant="body2" textAlign="center">
                    {feature.text}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <Box py={8}>
        <Container>
          <Box
            px={4}
            py={5}
            borderRadius={4}
            sx={{ backgroundColor: "#fafafa" }}
          >
            <Typography
              textAlign="center"
              mb={3}
              lineHeight={1.2}
              fontWeight="bold"
              fontSize={{ xs: "1.5rem", md: "2rem" }}
              marginX="auto"
              maxWidth={800}
            >
              Energy Pricing Intelligence Platform
            </Typography>
            <Typography
              textAlign="center"
              className="text-muted"
              mb={6}
              lineHeight={1.2}
              fontSize="1.1rem"
              marginX="auto"
              maxWidth={720}
            >
              Our platform updates daily, ensuring users have real-time insights
              into competitive pricing, helping firms optimize their strategies
              and stay ahead in the evolving energy market
            </Typography>
            {selectedState && (
              <Box>
                <Container>
                  <Row>
                    <Col xs={12} sm={4}>
                      <Box
                        pr={2}
                        sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}
                      >
                        {stateList?.map((val: any) => (
                          <Button
                            size="sm"
                            color="dark"
                            outline
                            key={val.state_id}
                            onClick={() => {
                              setSelectedState(val);
                            }}
                            active={selectedState.state_id === val.state_id}
                          >
                            {val.state_name}
                          </Button>
                        ))}
                      </Box>
                      {latestAvgRate && (
                        <Box mt={5} sx={{ display: "flex", gap: 2 }}>
                          <Box>
                            <Typography
                              fontSize="2rem"
                              fontWeight="bold"
                              lineHeight={1.2}
                            >{`${latestAvgRate.average_rate}¢`}</Typography>
                            <Typography
                              fontSize="0.825rem"
                              className="text-muted"
                              pl="3px"
                            >
                              Avg Rate
                            </Typography>
                          </Box>
                          <Box mt={1.5}>
                            {parseInt(latestAvgRate.percent) > 0 ? (
                              <span className="d-flex align-items-center gap-1 text-danger">
                                <i className="ri-arrow-right-up-line" />
                                {latestAvgRate.percent}
                              </span>
                            ) : (
                              <span className="d-flex align-items-center gap-1 text-success">
                                <i className="ri-arrow-right-down-line" />
                                {latestAvgRate.percent}
                              </span>
                            )}
                          </Box>
                        </Box>
                      )}
                    </Col>
                    <Col xs={12} sm={8}>
                      <RaceChart selectedState={selectedState} />
                    </Col>
                  </Row>
                </Container>
              </Box>
            )}
          </Box>
        </Container>
      </Box>

      <Box py={8} sx={{ backgroundColor: "#F2F7FA" }}>
        <Container>
          <Typography
            textAlign="center"
            mb={5}
            lineHeight={1.2}
            fontWeight="bold"
            fontSize={{ xs: "1.5rem", md: "2rem" }}
            marginX="auto"
            maxWidth={800}
          >
            Explore the Future of Energy Pricing Analysis
          </Typography>
          <Typography
            textAlign="center"
            className="text-muted"
            mb={6}
            lineHeight={1.2}
            fontSize="1.1rem"
            marginX="auto"
            maxWidth={720}
          >
            Ready to make informed decisions with reliable and comprehensive
            energy pricing data?
          </Typography>
          <Box sx={{ maxWidth: "640px" }} mx="auto">
            <Card>
              <CardBody>
                <ContactForm />
              </CardBody>
            </Card>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Home;
