import { useState } from "react";
import {
  Alert,
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { contactUs } from "../api/market";

const reg: any = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

const ContactForm = () => {
  const [isEmail, setIsEmail] = useState(true);
  const [formValue, setFormValue] = useState({
    first_name: "",
    last_name: "",
    company: "",
    email: "",
    question: "",
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    const res = await contactUs(formValue);
    if (res) {
      setIsSubmit(true);
    }
    setLoading(false);
  };

  return (
    <>
      {!isSubmit && (
        <Form className="needs-validation" onSubmit={(e) => handleSubmit(e)}>
          <FormGroup>
            <Label for="firstname">First Name</Label>
            <Input
              name="firstname"
              id="firstname"
              type="text"
              value={formValue.first_name}
              onChange={(e) => {
                setFormValue({
                  ...formValue,
                  first_name: e.target.value,
                });
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="lastname">Last Name</Label>
            <Input
              name="lastname"
              id="lastname"
              type="text"
              value={formValue.last_name}
              onChange={(e) => {
                setFormValue({
                  ...formValue,
                  last_name: e.target.value,
                });
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="company">Company</Label>
            <Input
              name="company"
              id="company"
              type="text"
              value={formValue.company}
              onChange={(e) => {
                setFormValue({
                  ...formValue,
                  company: e.target.value,
                });
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              name="email"
              id="email"
              type="email"
              required
              value={formValue.email}
              onChange={(e) => {
                setFormValue({
                  ...formValue,
                  email: e.target.value,
                });
                setIsEmail(reg.test(e.target.value));
              }}
              invalid={!isEmail}
            />
            {!isEmail ? (
              <FormFeedback type="invalid">
                Please input current email
              </FormFeedback>
            ) : null}
          </FormGroup>
          <FormGroup>
            <Label for="question">Message</Label>
            <Input
              name="question"
              id="question"
              type="textarea"
              required
              value={formValue.question}
              onChange={(e) => {
                setFormValue({
                  ...formValue,
                  question: e.target.value,
                });
              }}
            />
          </FormGroup>
          <Button color="secondary" type="submit" disabled={loading}>
            Submit
          </Button>
        </Form>
      )}
      {isSubmit && (
        <Alert
          color="success"
          style={{ padding: "80px 0", textAlign: "center" }}
        >
          <img src="/img/icon-success.png" width={60} alt="" />
          <strong style={{ fontSize: "1.4rem", display: "block" }}>
            Submit Successfully!
          </strong>
          <p style={{ fontSize: "1rem" }}>We will contact you ASAP.</p>
        </Alert>
      )}
    </>
  );
};

export default ContactForm;
