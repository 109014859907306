import { api } from "../config";

export const getTotalNum = async () => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  const url = `${api.API_URL}Index/total_data`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

export const getLatestAvgRate = async (state_id) => {
  const formData = new FormData();
  formData.append("state_id", state_id);

  const requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };
  const url = `${api.API_URL}Index/state_average_rate`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

export const getLastMonthAvgRate = async (state_id) => {
  const formData = new FormData();
  formData.append("state_id", state_id);

  const requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };
  const url = `${api.API_URL}Index/utilities_average_rate`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

