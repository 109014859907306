import React from "react";
import "../../node_modules/universal-footer/src/index.css";
// import { Col, Container, Row } from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer id="uni-ft">
        <div id="uni-ft-container">
          <div id="uni-ft-logo">
            <a
              href="https://www.eiqdigital.com/"
              target="_blank"
              rel="noopener"
            >
              <img src="/img/eiqdigital_logo_0.png" height="40" alt="" />
            </a>
            <p style={{ marginBottom: 0 }}>
              AI-First, Data and Technology Leader
            </p>
            <p
              style={{
                marginTop: 6,
                fontStyle: "italic",
                fontSize: 13,
                lineHeight: 1.1,
                color: "#999",
              }}
            >
              Power the Energy Industry's Digital & Sustainability Transition
            </p>
          </div>
          <div id="uni-ft-nav">
            <div>
              <h5>Products</h5>
              <ul>
                <li>
                  <a
                    href="https://www.powerlego.com/"
                    target="_blank"
                    rel="noopener"
                  >
                    PowerLego
                  </a>
                </li>
                <li>
                  <a href="https://eiqhome.com/" target="_blank" rel="noopener">
                    EIQhome
                  </a>
                </li>
                <li>
                  <a
                    href="https://eiqshopping.com/"
                    target="_blank"
                    rel="noopener"
                  >
                    EIQShopping
                  </a>
                </li>
                <li>
                  <a href="https://esiid.io/" target="_blank" rel="noopener">
                    ESIID.io
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.billreader.com/"
                    target="_blank"
                    rel="noopener"
                  >
                    BillReader
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h5>Support</h5>
              <ul>
                <li>
                  <a
                    href="https://www.powerrateindex.org/contact-us"
                    target="_blank"
                    rel="noopener"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h5>Follow us on</h5>
              <ul id="uni-ft-social-links">
                <li>
                  <a
                    href="https://www.facebook.com/p/eIQdigital-61552099297028/"
                    target="_blank"
                    rel="noopener"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="36"
                    >
                      <path
                        fill="#000"
                        fill-rule="nonzero"
                        d="M18 0C8.075 0 0 8.075 0 18s8.075 18 18 18 18-8.075 18-18S27.925 0 18 0m4.476 18.634h-2.928v10.439h-4.34v-10.44h-2.062v-3.688h2.063v-2.386c0-1.71.812-4.38 4.379-4.38l3.214.013v3.58h-2.334c-.38 0-.92.19-.92 1.005v2.168h3.307z"
                      ></path>
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/eiqdigital/"
                    target="_blank"
                    rel="noopener"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="36"
                    >
                      <path
                        fill="#000"
                        fill-rule="evenodd"
                        d="M18 0c9.934 0 18 8.066 18 18s-8.066 18-18 18S0 27.934 0 18 8.066 0 18 0m-5.628 28.116V14.059H7.7v14.057zm16.849 0v-8.061c0-4.318-2.306-6.327-5.38-6.327-2.479 0-3.59 1.364-4.21 2.321v-1.99h-4.673c.062 1.319 0 14.057 0 14.057h4.672v-7.85c0-.42.03-.84.154-1.141.337-.84 1.107-1.709 2.397-1.709 1.69 0 2.367 1.29 2.367 3.179v7.52zM10.067 7.28c-1.599 0-2.643 1.051-2.643 2.429 0 1.349 1.013 2.429 2.581 2.429h.03c1.63 0 2.644-1.08 2.644-2.43-.03-1.377-1.014-2.428-2.612-2.428"
                      ></path>
                    </svg>
                  </a>
                </li>
              </ul>
              <div id="uni-ft-aws">
                <a href="https://aws.amazon.com/what-is-cloud-computing">
                  <img
                    src="https://d0.awsstatic.com/logos/powered-by-aws.png"
                    alt="Powered by AWS Cloud Computing"
                  />
                </a>
              </div>
            </div>
          </div>
          <div id="uni-ft-copyright">
            2025 © EIQdigital. All Rights Reserved.
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
