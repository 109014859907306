import { useState, useEffect, useRef } from "react";
import Loader from "./Common/Loader";
import ReactECharts from "echarts-for-react";
import { RaceChartSection, RaceChartWrapper } from "./Styles/Chart.styled";
// import { errornotify } from "./Message";
import { getLastMonthAvgRate } from "../api/home";
import { Typography } from "@mui/material";

type DatasetItem = Array<number | string>;

const colors = [
  "#37A2DA",
  "#32C5E9",
  "#67E0E3",
  "#9FE6B8",
  "#FFDB5C",
  "#ff9f7f",
  "#fb7293",
  "#E062AE",
  "#E690D1",
  "#e7bcf3",
  "#9d96f5",
  "#8378EA",
  "#96BFFF",
];

const initialChartOptions = {
  grid: {
    top: 20,
    bottom: 32,
    left: 180,
    right: 44,
  },
  xAxis: {
    max: "dataMax",
    min: function (value: any) {
      return value.min - 0.5;
    },
    axisLabel: {
      // formatter: (n: number) => '' + Math.round(n),
      precision: 0.25,
    },
  },
  dataset: {
    source: [],
  },
  yAxis: {
    type: "category",
    max: 9,
    axisLabel: {
      show: true,
      fontSize: 14,
    },
    animationDuration: 600,
    animationDurationUpdate: 600,
    inverse: true,
  },
  series: [
    {
      // realtimeSort: true,
      seriesLayoutBy: "column",
      type: "bar",
      barMaxWidth: 40,
      itemStyle: {
        color: (param: any) => {
          return colors[param.data[0].length % 10] || "#f49f42";
        },
      },
      encode: {
        x: 1,
        y: 0,
      },
      label: {
        show: true,
        precision: 0.25,
        position: "right",
        valueAnimation: true,
        formatter: (val: any) => val.data[1] + "¢",
      },
    },
  ],
  animationDuration: 0,
  animationDurationUpdate: 2000,
  animationEasing: "linear",
  animationEasingUpdate: "linear",
};

type SelectState = {
  state_id: string;
  state_name: string;
};

export const RaceChart = ({
  selectedState,
}: {
  selectedState: SelectState;
}) => {
  const [loading, setLoading] = useState(false);
  const [formattedData, setFormattedData] = useState<{
    [key: string]: DatasetItem[];
  }>({});
  const [allDates, setAllDates] = useState<string[]>([]);
  const [chartOptions, setChartOptions] = useState(initialChartOptions);
  const currentDateIndex = useRef(0);
  const timer = useRef<ReturnType<typeof setTimeout> | "">("");

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const res = await getLastMonthAvgRate(selectedState.state_id);
        if (res?.status === 1) {
          const processedData = processApiData(res.response);
          setFormattedData(processedData);
          setAllDates(Object.keys(processedData));
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedState) {
      if (timer.current) {
        clearInterval(timer.current);
      }
      currentDateIndex.current = 0;
      setChartOptions(initialChartOptions);
      getData();
    }
  }, [selectedState]);

  useEffect(() => {
    if (allDates.length === 0 || !formattedData) return;

    const currentDate = allDates?.[allDates.length - 1];
    const dailyDate = formattedData?.[currentDate] || [];
    const chartData =
      dailyDate?.map((item: any) => [item.utility_name, item.average_rate]) ||
      [];
    setChartOptions((prev: any) => ({
      ...prev,
      dataset: {
        source: chartData,
      },
      yAxis: { ...prev.yAxis, max: chartData.length - 1 },
    }));

    // timer.current = setInterval(() => {
    //   if (currentDateIndex.current >= allDates.length) {
    //     clearInterval(timer.current);
    //     return;
    //   }

    //   const currentDate = allDates[currentDateIndex.current];

    //   const dailyDate = formattedData[currentDate];
    //   // .sort(
    //   //   (a: any, b: any) => a.average_rate - b.average_rate
    //   // );

    //   const chartData = dailyDate.map((item: any) => [
    //     item.utility_name,
    //     item.average_rate,
    //   ]);
    //   setChartOptions((prev: any) => ({
    //     ...prev,
    //     dataset: {
    //       source: chartData,
    //     },
    //     yAxis: { ...prev.yAxis, max: chartData.length - 1 },
    //   }));

    //   currentDateIndex.current += 1;
    // }, 2000);

    // return () => clearInterval(timer.current);
  }, [allDates, formattedData]);

  const processApiData = (data: any) => {
    const result: any = {};
    Object.entries(data).forEach(([date, values]) => {
      result[date] = values;
    });
    const sortedResult = sortUtilitiesByName(result);
    return sortedResult;
  };

  const sortUtilitiesByName = (data: any) => {
    const sortedData: any = {};
    for (const date in data) {
      sortedData[date] = data[date].sort((a: any, b: any) =>
        a.utility_name.localeCompare(b.utility_name, "en", {
          sensitivity: "base",
        })
      );
    }
    return sortedData;
  };

  return (
    <RaceChartSection style={{ marginTop: "-12px" }}>
      <RaceChartWrapper style={{ height: "360px" }}>
        {loading ? (
          <Loader />
        ) : (
          // isNoData ? (
          //   <p>No Data</p>
          // ) :
          <ReactECharts option={chartOptions} />
        )}
      </RaceChartWrapper>
      <Typography
        fontSize="18px"
        mt={2}
        textAlign="center"
        className="text-muted"
      >
        {/* {`Average rate by daily in ${selectedState?.state_name}`}{" "}
        <span>
          {allDates[currentDateIndex.current]
            ? `(${allDates[currentDateIndex.current]})`
            : ""}
        </span> */}
        Today's Avg Rate by {selectedState?.state_id === 'TX' ? 'TDU' : 'Utility'}
      </Typography>
    </RaceChartSection>
  );
};
