import { Helmet } from "react-helmet";
import { Container, Card, CardBody, CardHeader } from "reactstrap";
import ContactForm from "../Components/ContactForm";

export default function ContactUs() {
  return (
    <div className="page-content">
      <Helmet>
        <title>PowerRateIndex - Contact Us</title>
      </Helmet>
      <Container>
        <Card>
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Contact Us</h4>
          </CardHeader>
          <CardBody>
            <ContactForm />
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}
