import { createContext, useEffect, useState } from "react";
import { fetchLoginProviderList } from "../api/market";
import { Helmet } from "react-helmet";
import MarketAvgRateTx from "../Components/MarketAvgRateTx";
import { errornotify } from "../Components/Message";
import { useSelector } from "react-redux";
import MarketAvgRateUsn from "../Components/MarketAvgRateUsn";
import { selectMyProvider } from "../store/myProvider/selector";
import { Container } from "reactstrap";
import MarketLowestRateTx from "../Components/MarketLowestRateTx";
import MarketLowestRateUsn from "../Components/MarketLowestRateUsn";
import USN_PTCRateTrends from "../Components/USN_PTCRateTrends";
import USN_PeaktimeRateTrends from "../Components/USN_PeaktimeRateTrends";
// import { MySummary } from "../Components/MySummary";
// import USN_AllPlansMarketView from "../Components/USN_AllPlansMarketView";
// import LoginMarketSummary from "../Components/LoginMarketSummary";
// import LoginTopPlan from "../Components/LoginTopPlan";
// import MarketSummary from "../Components/MarketSummary";
// import USN_AverageRateMarketView from "../Components/USN_AverageRateMarketView";

export const Context = createContext<any>({});

// Market Insight Page
export const LoginMarket = () => {
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);
  const isTX = currentLocation?.state_id === "TX";
  const myProvider = useSelector(selectMyProvider);
  const [allProvider, setAllProvider] = useState([]);

  const getAllProvider = async () => {
    const res = await fetchLoginProviderList();
    if (res.status !== 1) {
      setAllProvider([]);
      errornotify(res.message || "Error");
      // return;
    }
    setAllProvider(res.response);
  };

  useEffect(() => {
    getAllProvider();
  }, [currentLocation]);

  return (
    <div className="page-content">
      <Helmet>
        <title>PowerRateIndex</title>
      </Helmet>
      <Context.Provider
        value={{
          provider: myProvider || {
            provider_id: "",
            provider_name: "",
            time: "",
          },
          allProvider,
          setAllProvider,
        }}
      >
        {/* <MySummary /> */}
        {isTX ? <TxComps /> : <UsnComps />}
      </Context.Provider>
    </div>
  );
};

const TxComps = () => {
  return (
    <Container>
      <MarketAvgRateTx />
      <MarketLowestRateTx />
      {/* <LoginMarketSummary /> */}
      {/* <LoginTopPlan /> */}
      {/* <MarketSummary /> */}
    </Container>
  );
};

const UsnComps = () => {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <Container>
      <ul className="nav nav-tabs page-tabs mb-3" role="tablist">
        <li className="nav-item" role="presentation">
          <a
            className={`nav-link ${activeTab === 1 ? "active" : ""}`}
            data-bs-toggle="tab"
            href="#market-insight"
            role="tab"
            aria-selected="false"
            onClick={(evt) => {
              evt.preventDefault();
              setActiveTab(1);
            }}
          >
            Market Insight
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            className={`nav-link ${activeTab === 2 ? "active" : ""}`}
            data-bs-toggle="tab"
            href="#utility-insight"
            role="tab"
            aria-selected="true"
            onClick={(evt) => {
              evt.preventDefault();
              setActiveTab(2);
            }}
          >
            Utility Insight (PTC)
          </a>
        </li>
      </ul>
      {activeTab === 1 && (
        <>
          <MarketAvgRateUsn />
          <MarketLowestRateUsn />
        </>
      )}
      {activeTab === 2 && (
        <>
          <USN_PTCRateTrends />
          <USN_PeaktimeRateTrends />
        </>
      )}
      {/* <USN_AverageRateMarketView /> */}
      {/* <USN_AllPlansMarketView /> */}
    </Container>
  );
};
