/** Home Page */
import Home from "./home";
// import { useSelector } from "react-redux";
// import MarketTrend from "../../Components/MarketTrend";
// import LoginMarketAverageRate from "../../Components/LoginMarketAverageRate";
// import PowerRateIndex from "../../Components/PowerRateIndex";
// import GetAccessModal from "../../Components/Common/GetAccessModal";
// import USN_AverageRateGlance from "../../Components/USN_AverageRateGlance";
// import USN_ElectricityRateTrends from "../../Components/USN_ElectricityRateTrends";
// import { Container } from "reactstrap";
// import NewHome from "./newHome";

const Index = () => {
  // const currentLocation = useSelector<
  //   any,
  //   { state_id: string; state_name: string }
  // >((state) => state.Location.currentLocation);

  return (
    <div className="layout-wrapper landing">
      <Home />
      {/* <div className="bg-light pt-5 pb-5">
        <NewHome />
        {currentLocation?.state_id === "TX" ? <TxComps /> : <UsnComps />}
      </div> */}
    </div>
  );
};

// const TxComps = () => {
//   return (
//     <Container>
//       <MarketTrend />
//       <PowerRateIndex />
//       <LoginMarketAverageRate />
//     </Container>
//   );
// };

// const UsnComps = () => {
//   return (
//     <Container>
//       <USN_ElectricityRateTrends />
//       <USN_AverageRateGlance />
//       <GetAccessModal />
//     </Container>
//   );
// };

export default Index;
