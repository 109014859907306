import React, { useEffect, useState } from "react";
import Loader from "./Common/Loader";
import { fetchLoginMarketPrice } from "../api/market";
import "../styles/market.css";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import CountUp from "react-countup";
import { errornotify } from "./Message";
import { jumpTo } from "./UrlModal";
import { IconButton, Tooltip, Typography } from "@mui/material";
// import AiSummary from "./AiSummary";
import { useSelector } from "react-redux";
import { selectUtility } from "../store/utility/selector";
import { selectLocation } from "../store/location/selector";

interface resObjectInfo {
  [key: string]: any;
}

let resObject: Array<resObjectInfo> = [{}, {}, {}];
let resInfoData: Array<Array<resObjectInfo>> = [[], [], []];
const isMobileLayout = document.body.clientWidth <= 786;

const LoginMarketSummary = () => {
  const [resMarketPrice, setResMarketPrice] = useState(resObject);
  const [resMarketDataInfo, setResMarketDataInfo] = useState(resInfoData);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState<{ start: string; end: string }>({
    start: "",
    end: "",
  });
  const [showTooltip, setShowTooltip] = useState([false, false, false]);
  const [dataString, setDataString] = useState("");
  const currentLocation = useSelector(selectLocation);
  const currentUtility = useSelector(selectUtility);

  const getMarketPrice = async () => {
    setIsLoading(true);
    const res = await fetchLoginMarketPrice(currentUtility.utility_id);
    if (res.status !== 1) {
      setDate({
        start: "",
        end: "",
      });
      setResMarketDataInfo(resInfoData);
      setResMarketPrice(resObject);
      // errornotify(res.message || "Market Error");
      setIsLoading(false);
      return;
    }
    setDate(res.response.time);
    let data: Array<Object> = [];
    let infoData: Array<Array<Object>> = [];
    Object.keys(res.response).forEach((n) => {
      data.push(res.response[n]);
      infoData.push([]);
      Object.keys(res.response[n]).forEach((c: any) => {
        if (Number(c) || Number(c) === 0) {
          infoData[infoData.length - 1].push(res.response[n][c]);
        }
      });
    });
    setResMarketDataInfo(infoData);
    setResMarketPrice(data);

    if (res?.response) {
      setDataString(`
                ##Market Lowest Rate Plans
                ${JSON.stringify(res.response)}
            `);
    } else {
      setDataString("");
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!currentUtility) return;
    getMarketPrice();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUtility]);

  const toBadge = (num: any) => {
    if (!num) return;
    let newNum = num.substr(0, num.length - 1);
    if (+newNum > 0) {
      return (
        <span className="badge bg-light mb-0 text-danger">
          <i className={" align-middle ri-arrow-right-up-line"}></i>
          <CountUp
            start={0}
            suffix="%"
            prefix=""
            end={+newNum}
            decimals={2}
            duration={1}
          />
        </span>
      );
    } else if (+newNum < 0) {
      return (
        <span className="badge bg-light mb-0 text-success">
          <i className={"align-middle ri-arrow-right-down-line"}></i>
          <CountUp
            start={0}
            suffix="%"
            prefix=""
            end={+newNum}
            decimals={2}
            duration={1}
          />
        </span>
      );
    } else {
      return (
        <span className="badge bg-light mb-0">
          <i className={"align-middle"}></i>
          {num}
        </span>
      );
    }
  };

  const handleTouchStart = (id: any) => {
    let arr = showTooltip;
    arr.splice(id, 1, !showTooltip[id]);
    setShowTooltip([...arr]);
  };

  const Tooltips = (props: any) => {
    let { id } = props;
    return isMobileLayout ? (
      <Tooltip
        title={`Based on ${date?.start.substr(0, 7) || "-"} and ${
          date?.end.substr(0, 7) || "-"
        } data`}
        placement={"top"}
        style={{ padding: 0 }}
        open={showTooltip[id]}
      >
        <IconButton
          onTouchStart={() => {
            handleTouchStart(id);
          }}
        >
          <img src="/question-mark-icon.png" alt="" width={17} />
        </IconButton>
      </Tooltip>
    ) : (
      <Tooltip
        title={`Based on ${date?.start.substr(0, 7) || "-"} and ${
          date?.end.substr(0, 7) || "-"
        } data`}
        placement={"top"}
        style={{ padding: 0 }}
      >
        <IconButton>
          <img src="/question-mark-icon.png" alt="" width={17} />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <Card style={{ minHeight: "400px" }}>
      <CardHeader className="d-flex align-items-center justify-content-between">
        <div>
          <h4 className="card-title mb-0 flex-grow-1">
            Market’s Lowest Rate Plans
          </h4>
          {currentLocation && currentUtility && (
            <Typography
              variant="subtitle2"
              className="text-muted"
            >{`${currentLocation.state_name} - ${currentUtility.utility_name}`}</Typography>
          )}
        </div>
        {date?.start && date?.end && (
          <div className="flex-shrink-0">
            <span className="fs-14 text-muted" style={{ fontWeight: "400" }}>
              Based on {date?.start.substr(0, 7) || "-"} and{" "}
              {date?.end.substr(0, 7) || "-"} data
            </span>
          </div>
        )}
      </CardHeader>
      {/* <AiSummary data={dataString} /> */}
      <CardBody>
        {isLoading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <Row>
              <Col span={4}>
                <Card className="card-height-100">
                  <CardHeader>
                    <div className="d-flex">
                      <div style={{ width: "100%" }}>
                        <p className="fw-medium text-muted mb-0">500 kWh</p>
                        <div className="d-flex  justify-content-between align-items-flex-end">
                          <div className="d-flex">
                            <h4
                              className="mt-3 me-3 ff-secondary fw-semibold counter-value"
                              data-target={resMarketPrice[0].average_price500}
                            >
                              {resMarketPrice[0].average_price500}¢
                            </h4>
                            <h4
                              className="mt-3 ff-secondary fw-semibold counter-value"
                              data-target={resMarketPrice[0].trend_rate500}
                            >
                              {toBadge(resMarketPrice[0].trend_rate500)}
                            </h4>
                          </div>
                          <Tooltips id={1} />
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="plan_rank">
                    <ul className="list-group list-group-flush border-dashed">
                      {resMarketDataInfo[0].map((item, index) => (
                        <li className="list-group-item ps-0" key={index}>
                          <Row className="align-items-center g-3">
                            <div className="col-auto">
                              <div className="avatar-sm p-1 py-2 h-auto bg-light rounded-3">
                                <div className="text-center">
                                  <h5 className="mb-0">{+index + 1}</h5>
                                </div>
                              </div>
                            </div>
                            <div className="col">
                              <h5 className="text-muted mt-0 mb-1 fs-13 fw-semibold">
                                {item.provider_name}
                              </h5>
                              <div
                                className="text-reset fs-15 mb-0"
                                onClick={() => jumpTo(item.plan_id, item.pd_id)}
                                role="button"
                              >
                                {item.plan_name}
                              </div>
                            </div>
                            <div className="col-auto">{item.price500}¢</div>
                          </Row>
                        </li>
                      ))}
                    </ul>
                  </CardBody>
                </Card>
              </Col>
              <Col span={4}>
                <Card>
                  <CardHeader>
                    <div className="d-flex justify-content-between">
                      <div style={{ width: "100%" }}>
                        <p className="fw-medium text-muted mb-0">1000 kWh</p>
                        <div className="d-flex  justify-content-between align-items-flex-end">
                          <div className="d-flex">
                            <h4
                              className="mt-3 me-3 ff-secondary fw-semibold counter-value"
                              data-target={resMarketPrice[1].average_price1000}
                            >
                              {resMarketPrice[1].average_price1000}¢
                            </h4>
                            <h4 className="mt-3 ff-secondary fw-semibold">
                              {toBadge(resMarketPrice[1].trend_rate1000)}
                            </h4>
                          </div>
                          <Tooltips id={2} />
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="plan_rank">
                    <ul className="list-group list-group-flush border-dashed">
                      {resMarketDataInfo[1].map((item, index) => (
                        <li className="list-group-item ps-0" key={index}>
                          <Row className="align-items-center g-3">
                            <div className="col-auto">
                              <div className="avatar-sm p-1 py-2 h-auto bg-light rounded-3">
                                <div className="text-center">
                                  <h5 className="mb-0">{+index + 1}</h5>
                                </div>
                              </div>
                            </div>
                            <div className="col">
                              <h5 className="text-muted mt-0 mb-1 fs-13 fw-semibold">
                                {item.provider_name}
                              </h5>
                              <div
                                className="text-reset fs-15 mb-0"
                                onClick={() => jumpTo(item.plan_id, item.pd_id)}
                                role="button"
                              >
                                {item.plan_name}
                              </div>
                            </div>
                            <div className="col-auto">{item.price1000}¢</div>
                          </Row>
                        </li>
                      ))}
                    </ul>
                  </CardBody>
                </Card>
              </Col>
              <Col span={4}>
                <Card>
                  <CardHeader>
                    <div className="d-flex justify-content-between">
                      <div style={{ width: "100%" }}>
                        <p className="fw-medium text-muted mb-0">2000 kWh</p>
                        <div className="d-flex  justify-content-between align-items-flex-end">
                          <div className="d-flex">
                            <h4
                              className="mt-3 me-3 ff-secondary fw-semibold counter-value"
                              data-target={resMarketPrice[2].average_price2000}
                            >
                              {resMarketPrice[2].average_price2000}¢
                            </h4>
                            <h4 className="mt-3 ff-secondary fw-semibold">
                              {toBadge(resMarketPrice[2].trend_rate2000)}
                            </h4>
                          </div>
                          <Tooltips id={3} />
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="plan_rank">
                    <ul className="list-group list-group-flush border-dashed">
                      {resMarketDataInfo[2].map((item, index) => (
                        <li className="list-group-item ps-0" key={index}>
                          <Row className="align-items-center g-3">
                            <div className="col-auto">
                              <div className="avatar-sm p-1 py-2 h-auto bg-light rounded-3">
                                <div className="text-center">
                                  <h5 className="mb-0">{+index + 1}</h5>
                                </div>
                              </div>
                            </div>
                            <div className="col">
                              <h5 className="text-muted mt-0 mb-1 fs-13 fw-semibold">
                                {item.provider_name}
                              </h5>
                              <div
                                className="text-reset fs-15 mb-0"
                                onClick={() => jumpTo(item.plan_id, item.pd_id)}
                                role="button"
                              >
                                {item.plan_name}
                              </div>
                            </div>
                            <div className="col-auto">{item.price2000}¢</div>
                          </Row>
                        </li>
                      ))}
                    </ul>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </CardBody>
    </Card>
  );
};
export default LoginMarketSummary;
