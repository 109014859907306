import { useDispatch, useSelector } from "react-redux";
import { Collapse, Container, Spinner } from "reactstrap";
import SimpleBar from "simplebar-react";
import { selectLocation, selectStateList } from "../store/location/selector";
import { Link } from "react-router-dom";
import {
  selectUtility,
  selectUtilityList,
  selectIsLoading,
} from "../store/utility/selector";
import { changeLocation, changeUtility } from "../store/actions";
import { Typography } from "@mui/material";
import { useState } from "react";

const StateUtilitySidebar = () => {
  const dispatch = useDispatch();
  const currentLocation = useSelector(selectLocation);
  const currentUtility = useSelector(selectUtility);
  const stateList = useSelector(selectStateList);
  const utilityList = useSelector(selectUtilityList);
  const isLoadingUtilityList = useSelector(selectIsLoading);
  const electricityUtility = utilityList?.electricity || [];
  const [isShow, setIsShow] = useState(false);

  const handleChangeUtility = (utility) => {
    dispatch(changeUtility(utility));
    window.localStorage.setItem("utility", JSON.stringify(utility));
    setIsShow(false);
  };

  const handleChangeState = (state) => {
    dispatch(changeUtility(null));
    dispatch(changeLocation(state));
    window.localStorage.setItem("location", JSON.stringify(state));
  };

  return (
    <>
      <div className={`sidebar-menu ${isShow ? "active" : ""}`}>
        <div
          id="scrollbar"
          data-simplebar="init"
          class="h-100 simplebar-scrollable-y"
        >
          <SimpleBar id="scrollbar" className="h-100">
            <Container fluid className="p-0 m-0" style={{ maxWidth: "100%" }}>
              <Typography
                variant="h6"
                color="#a1b7bf"
                fontSize={12}
                fontWeight={600}
                sx={{
                  textTransform: "uppercase",
                  padding: "0.625rem 1.5rem",
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                State
              </Typography>
              <ul className="navbar-nav" id="navbar-nav">
                {stateList.map((state) => (
                  <li className="nav-item" key={state.state_id}>
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        if (currentLocation?.state_id !== state.state_id) {
                          handleChangeState(state);
                        }
                      }}
                      className="nav-link menu-link"
                      to="#"
                      data-bs-toggle="collapse"
                      aria-expanded={
                        currentLocation?.state_id === state.state_id
                      }
                    >
                      <span data-key="t-apps">{state.state_name}</span>
                    </Link>
                    {currentLocation?.state_id === state.state_id &&
                    isLoadingUtilityList ? (
                      <div className="pt-1 pb-3 ps-4">
                        <Spinner size="sm" color="white">
                          Loading...
                        </Spinner>
                      </div>
                    ) : (
                      <Collapse
                        className="sidebar-menu-dropdown"
                        isOpen={currentLocation?.state_id === state.state_id}
                      >
                        <ul className="nav nav-sm flex-column">
                          {electricityUtility.map((utility) => (
                            <li className="nav-item" key={utility.utility_id}>
                              <Link
                                to="#"
                                className={`nav-link ${
                                  currentUtility?.utility_id ===
                                  utility.utility_id
                                    ? "active"
                                    : ""
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (
                                    currentUtility?.utility_id !==
                                    utility.utility_id
                                  ) {
                                    handleChangeUtility(utility);
                                  }
                                }}
                              >
                                {utility.utility_name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </Collapse>
                    )}
                  </li>
                ))}
              </ul>
            </Container>
          </SimpleBar>
        </div>
        <div class="sidebar-background" onClick={() => setIsShow(false)} />
      </div>
      {!isShow && (
        <button id="change-utility-state-btn" onClick={() => setIsShow(true)}>
          Switch State and Utility
        </button>
      )}
    </>
  );
};

export default StateUtilitySidebar;
