export const menu = [
  {
    key: "home",
    name: "Home",
    path: "/",
    icon: "bx bx-home",
  },
  {
    key: "dashboard",
    name: "Dashboard",
    path: "/dashboard",
    icon: "bx bx-category-alt",
  },
  {
    key: "market",
    name: "Market Insight",
    path: "/market",
    icon: "bx bx-bar-chart-alt-2",
    hasSidebar: true,
  },
  {
    key: "plan-insight",
    name: "Plan Insight",
    path: "/plan-insight",
    icon: "bx bx-copy-alt",
    hasSidebar: true,
  },
  {
    key: "my-plan",
    name: "My Plan",
    path: "/my-plan",
    icon: "bx bx-copy-alt",
    hasSidebar: true,
  },
  {
    key: "tools",
    name: "Tools",
    path: "#",
    icon: "bx bx-copy-alt",
    subMenu: [
      {
        key: "plan_audit",
        name: "Plan Audit",
        path: "/mismatched",
        icon: "ri-table-line",
        isTxOnly: true,
      },
      {
        key: "profile_match",
        name: "Profile Match",
        path: "/customize",
        icon: "ri-layout-masonry-line",
        isTxOnly: true,
      },
    ],
  },
];
