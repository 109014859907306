import React, { useContext, useEffect, useState } from "react";
import "../styles/myplan.css";
import moment from "moment";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Spinner,
  Table,
  Modal,
  ModalHeader,
  Dropdown,
} from "reactstrap";
import DataTable from "react-data-table-component";
import Loader from "./Common/Loader";
import { DataSeriesTypes } from "../types";
import { useDispatch, useSelector } from "react-redux";
import { getKWHPrice } from "../store/actions";
import { NoResult } from "./Common/NoResult";
import { Context } from "../pages/LoginMarket";
import {
  exportUSNPlan,
  fetchUSNMarketPlanDetail,
  fetchUSNMarketPlan,
} from "../api/usnMarket";
import Datepicker from "./Datepicker";
import { selectUtility } from "../store/utility/selector";
import { RankChart } from "./Charts/RankChart";
import { Typography } from "@mui/material";

interface topPlanObj {
  base_rate: String;
  cancel_fee: String;
  created_ts: String;
  diff: any;
  efl_url: string;
  energy_charge1: any;
  plan_id: String;
  plan_name: String;
  price: String;
  provider_id: String;
  provider_name: String;
  term: string;
  utility_id: String;
  utility_name: String;
  rank: any;
  source: any;
  contract_term: String;
}
interface detail {
  plan: any;
  rank: any;
}

const calDate = (val: string) => {
  return moment(val, "YYYY-MM-DD").subtract(30, "days").format("YYYY-MM-DD");
};

const USN_AllPlansMarketView = () => {
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);
  const { provider } = useContext(Context);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [filterValue, setFilterValue] = useState({
    created_ts: moment().format("YYYY-MM-DD"),
    provider_id: "",
  });
  const [dataPlanList, setDataPlanList] = useState<Array<topPlanObj>>([]);
  const [checked, setChecked] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [termValue, setTermValue] = useState("All");
  const [showMeData, setShowMeData] = useState<Array<topPlanObj>>([]);
  const [sourceValue, setSourceValue] = useState("All");
  const [tablePage, setTablePage] = useState(1);
  const currentUtility = useSelector(selectUtility);

  const getPlanList = async (val: any) => {
    setIsLoading(true);
    setChecked(false);
    setDataPlanList([]);
    setTablePage(1);
    const res = await fetchUSNMarketPlan(
      currentLocation?.state_id,
      val.created_ts,
      currentUtility.utility_id,
      "1"
    );

    if (res.status !== 1 || res.response.length === 0) {
      return setIsLoading(false);
    }

    let iRank = 1;
    setDataPlanList(
      res.response.map((item: any) => {
        return {
          ...item,
          rank: iRank++,
        };
      })
    );
    setIsLoading(false);
  };
  useEffect(() => {
    if (currentUtility) {
      getPlanList(filterValue);
    }
  }, [filterValue, currentUtility]);

  const changeDate = (val: any) => {
    setFilterValue({
      ...filterValue,
      created_ts: moment(val).format("YYYY-MM-DD"),
    });
  };

  const showMeOnly = (flag: boolean) => {
    setChecked(flag);
    setTablePage(1);
    let myData: any = [];
    dataPlanList.forEach((item) => {
      if (item?.provider_id === provider?.provider_id) {
        myData.push(item);
      }
    });
    setShowMeData(myData);
  };

  const [open, setOpen] = React.useState(false);
  const [isShow, setIsShow] = useState(false);
  const [planDetail, setPlanDetail] = useState<detail>({
    plan: {},
    rank: 0,
  });

  const [detailData, setDetailData] = useState<DataSeriesTypes[]>([]);
  const [openSelect, setOpenSelect] = useState(false);
  const [hasRankTrend, setHasRankTrend] = useState(true);

  const handleOpen = async (plan_id: any, pd_id: any, rank: any) => {
    setOpen(true);
    setIsShow(true);
    dispatch(getKWHPrice(true));
    const res = await fetchUSNMarketPlanDetail(
      plan_id,
      pd_id,
      `${calDate(filterValue.created_ts)}&${filterValue.created_ts}`
    );
    let detailDataArr: any[] = [];
    setHasRankTrend(true);
    if (res.status !== 1 || res.response.rank_trend.length === 0) {
      detailDataArr = [];
      setHasRankTrend(false);
      return setIsShow(false);
    }

    res.response.rank_trend.map((item: any) => {
      if (item.rank !== 0)
        return detailDataArr.push({ x: item.date, y: item.rank });
      else return detailDataArr.push({ x: item.date, y: null });
    });

    setDetailData([
      { id: "Rank", data: detailDataArr.filter((i: any) => i.y) },
    ]);
    setPlanDetail({
      plan: res.response.plan,
      rank: rank,
    });
    setIsShow(false);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(getKWHPrice(false));
  };

  const exportList = async () => {
    setIsDownload(true);
    const res = await exportUSNPlan(
      currentLocation?.state_id,
      filterValue.created_ts,
      "1"
    );

    const fileName = res.headers
      .get("content-disposition")
      ?.split("filename=")[1];

    const data = await res.blob();

    const url = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.setAttribute(
      "download",
      fileName || `PlanList_${filterValue.created_ts}.xlsx`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsDownload(false);
  };

  const conditionalRowStyles: any = [
    {
      when: (row: any) => row?.provider_id === provider?.provider_id,
      style: { backgroundImage: "linear-gradient(-90deg,#ffffff, #F3EEFE)" },
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F2F2F2",
        paddingRight: 0,
      },
    },
  };

  const realData = checked ? showMeData : dataPlanList;
  let filterData = +termValue
    ? realData.filter((item) => +item.contract_term === +termValue)
    : realData;

  let terms: any[] = [];
  (sourceValue === "All" ? realData : filterData).map((item, index) => {
    return terms.push(item.contract_term);
  });
  terms.sort((a, b) => a - b);
  terms.unshift("All");
  let filterTerms = [...new Set(terms)];

  const TopPlan = () => {
    const columns = [
      {
        name: <span className="font-weight-bold fs-14">Rank</span>,
        selector: (row: any) => row.rank,
        width: "90px",
        sortable: true,
      },
      {
        name: <span className="font-weight-bold fs-14">Provider</span>,
        selector: (row: any) => row.provider_name,
      },
      {
        name: <span className="font-weight-bold fs-14">Plan Name</span>,
        selector: (row: any) => row.plan_name,
      },
      {
        name: <span className="font-weight-bold fs-14">Rate</span>,
        selector: (row: any) => `${row.rate}¢`,
        sortable: true,
      },
      {
        name: (
          <span className="font-weight-bold fs-14">
            <Dropdown
              isOpen={openSelect}
              toggle={() => setOpenSelect(!openSelect)}
            >
              <DropdownToggle tag="button" className="btn ps-0 pe-0">
                <b>
                  {Number.isInteger(parseInt(termValue))
                    ? `${termValue} Month`
                    : "Term"}
                </b>{" "}
                <i className="mdi mdi-chevron-down"></i>
              </DropdownToggle>
              <DropdownMenu
                style={{ overflowY: "scroll", maxHeight: "15rem", zIndex: "1" }}
              >
                {filterTerms.map((item: any) => (
                  <DropdownItem key={item} onClick={() => setTermValue(item)}>
                    {item}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </span>
        ),
        selector: (row: any) => row.contract_term,
        style: {
          paddingLeft: "1.5rem",
        },
        width: "95px",
      },
      {
        name: <span className="font-weight-bold fs-14">Cancellation Fee</span>,
        selector: (row: any) => row.cancel_fee,
      },
      {
        name: <span className="font-weight-bold fs-14">Source</span>,
        selector: (row: any) => row.source,
      },
      {
        name: <span className="font-weight-bold fs-14"></span>,
        selector: (row: any) => {
          // const findEfl = row?.documents?.find((item: any) =>
          //   item?.description?.toLowerCase().includes("efl")
          // );

          return (
            <div className="d-flex flex-wrap gap-2">
              <Button
                color="secondary"
                outline
                className="btn btn-sm"
                onClick={() => handleOpen(row.plan_id, row.pd_id, row.rank)}
              >
                Detail
              </Button>
              {/* {findEfl && (
                <Button
                  onClick={() => {
                    window.open(findEfl.location, "_blank", "noreferrer");
                  }}
                  outline
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-sm"
                >
                  EFL
                </Button>
              )} */}
            </div>
          );
        },
      },
    ];
    return (
      <DataTable
        columns={columns}
        keyField="plan_id"
        data={filterData}
        conditionalRowStyles={conditionalRowStyles}
        paginationPerPage={10}
        customStyles={customStyles}
        pagination
        paginationDefaultPage={tablePage}
        onChangePage={(page) => setTablePage(page)}
      />
    );
  };

  const filterSelections = () => {
    let list = [],
      key = 0;
    if (termValue !== "All") {
      list.push(
        <button
          key={key++}
          className="d-flex align-items-center btn-soft-secondary btn-sm me-2"
          onClick={() => setTermValue("All")}
        >
          {`Term: ${termValue}`}
          <i className="bx bx-x fs-15 ms-2" />
        </button>
      );
    }
    if (sourceValue !== "All") {
      list.push(
        <button
          key={key++}
          className="d-flex align-items-center btn-soft-secondary btn-sm me-2"
          onClick={() => setSourceValue("All")}
        >
          {`Term: ${sourceValue}`}
          <i className="bx bx-x fs-15 ms-2" />
        </button>
      );
    }
    return list.length > 0 ? (
      <div className="d-flex align-items-center">
        Active Filters:
        <div className="d-flex ms-3">{list}</div>
      </div>
    ) : null;
  };

  return (
    <>
      <Card
        style={{
          position: "relative",
          paddingBottom: "1rem",
          minHeight: "40rem",
        }}
      >
        <CardHeader className="align-items-center justify-content-between d-flex">
          <div>
            <h4 className="card-title mb-0 flex-grow-1">All Market Plans</h4>
            {currentLocation && currentUtility && (
              <Typography
                variant="subtitle2"
                className="text-muted"
              >{`${currentLocation.state_name} - ${currentUtility.utility_name}`}</Typography>
            )}
          </div>
          <div className="flex-shrink-0">
            {!isDownload ? (
              <Button
                color="secondary"
                onClick={exportList}
                className="btn-label"
              >
                <i className="bx bx-download label-icon align-middle fs-17 me-2"></i>
                <span>Download</span>
              </Button>
            ) : (
              <Button color="secondary" className="btn-load" outline>
                <span className="d-flex align-items-center">
                  <Spinner size="sm" className="flex-shrink-0">
                    {" "}
                    Loading...{" "}
                  </Spinner>
                  <span className="flex-grow-1 ms-2">Loading...</span>
                </span>
              </Button>
            )}
          </div>
        </CardHeader>
        <CardBody>
          <div className="d-flex flex-wrap justify-content-end gap-2">
            <div className="d-flex align-item-center gap-3">
              <div className="form-check form-switch form-switch-md d-flex align-items-center form-switch-secondary">
                <Label className="text-muted d-flex align-items-center justify-content-between mb-0">
                  <Input
                    className="code-switch"
                    type="checkbox"
                    value=""
                    checked={checked}
                    onChange={(e) => {
                      showMeOnly(e.target.checked);
                    }}
                  />
                  View My Plans
                </Label>
              </div>
              <Datepicker
                handleChangeDate={(val: any) => changeDate(val)}
                disabled={isLoading}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between mb-4">
            <div>{filterSelections()}</div>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="rdt_out">
              {dataPlanList?.length > 0 ? <TopPlan /> : <NoResult />}
            </div>
          )}
        </CardBody>
      </Card>
      <Modal size="lg" isOpen={open} toggle={handleClose} centered>
        <ModalHeader toggle={handleClose}>Plan Detail</ModalHeader>
        <div className="modal-body">
          {isShow ? (
            <Loader />
          ) : (
            <React.Fragment>
              <Table className="align-middle table-fixed mb-0">
                <thead>
                  <tr>
                    <th scope="col">Rank</th>
                    <th scope="col">Provider Name</th>
                    <th scope="col">Utility Name</th>
                    <th scope="col">Term</th>
                    <th scope="col">Base Rate</th>
                    <th scope="col">Plan Source</th>
                    <th scope="col">Cancellation Fee</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">{planDetail.rank}</th>
                    <td>{planDetail.plan.provider_name}</td>
                    <td>{planDetail.plan.utility_name}</td>
                    <td>{planDetail.plan.contract_term}</td>
                    <td>
                      {planDetail.plan.rate}
                      {planDetail.plan.rate ? "¢" : ""}
                    </td>
                    <td>{planDetail.plan.source || ""}</td>
                    <td>{planDetail.plan.cancel_fee}</td>
                  </tr>
                </tbody>
              </Table>
              {hasRankTrend ? (
                <>
                  <RankChart title="Rank Trend" data={detailData} />
                </>
              ) : (
                <NoResult />
              )}
            </React.Fragment>
          )}
        </div>
      </Modal>
    </>
  );
};

export default USN_AllPlansMarketView;
