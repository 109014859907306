import { BarDatum, ResponsiveBar } from "@nivo/bar";
import {
  ChartBlock,
  ChartTitle,
  ChartWrapper,
  CustomTooltip,
  CustomTooltipY,
  CustomTooltipYLabel,
  CustomTooltipYVal,
} from "../Styles/Chart.styled";
import { BaseChartProps } from "../../common/chart-config";
import { NoResult } from "../Common/NoResult";
import { mergeObjects } from "./helper";
import moment from "moment";
import Loader from "../Common/Loader";

interface ChartProps {
  title?: string;
  data: BarDatum[];
  isLoading?: boolean;
}

export const PriceBarChart: React.FC<ChartProps> = ({
  title,
  data,
  isLoading = false,
}) => {
  let isMobile = document.body.clientWidth <= 786;

  const dataSize = data?.length || 0;

  const getTickValues = (data: any, interval = 3) => {
    return data
      .filter((_: any, index: any) => index % interval === 0)
      .map((d: any) => d.date);
  };

  const chatConfig = {
    keys: ["value"],
    indexBy: "date",
    margin: { bottom: 60 },
    padding: 0.7,
    valueScale: { type: "linear" },
    indexScale: { type: "band", round: true },
    colors: "rgb(77, 175, 74)",
    axisBottom: {
      tickValues: isMobile ? 1 : getTickValues(data, dataSize > 12 ? 2 : 1),
      format: (value: string) =>
        moment(value, "YYYY-MM-DD").format("MMM, YYYY"),
    },
    axisLeft: {
      legend: "Rate/kWh (in cents)",
    },
    enableLabel: false,
    fill: [],
    legends: [],
    tooltip: (point: any) => {
      if (point) {
        const { data, color } = point || {};
        if (data?.value && data?.date) {
          const xValFormatted = data?.date
            ? moment(data?.date, "YYYY-MM-DD").format("MMM, YYYY")
            : "";
          return (
            <CustomTooltip>
              <CustomTooltipY>
                <CustomTooltipYLabel color={color}>
                  {xValFormatted}
                </CustomTooltipYLabel>
                <CustomTooltipYVal>{`${data?.value}¢`}</CustomTooltipYVal>
              </CustomTooltipY>
            </CustomTooltip>
          );
        }
      }
      return null;
    },
  };

  const newConfig = mergeObjects(BaseChartProps, chatConfig);

  return (
    <ChartBlock style={{ minHeight: 300 }}>
      {title && <ChartTitle>{title}</ChartTitle>}
      <ChartWrapper xs>
        {isLoading ? (
          <Loader />
        ) : data?.length === 0 ? (
          <NoResult />
        ) : (
          <ResponsiveBar {...newConfig} data={data} />
        )}
      </ChartWrapper>
    </ChartBlock>
  );
};
