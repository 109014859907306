import { useSelector } from "react-redux";
import { selectMyProvider } from "../store/myProvider/selector";
import { createContext, useEffect, useState } from "react";
import { fetchLoginProviderList } from "../api/market";
import { errornotify } from "../Components/Message";
import { Helmet } from "react-helmet";
import { Container } from "reactstrap";
import LoginMarketSummary from "../Components/LoginMarketSummary";
import LoginTopPlan from "../Components/LoginTopPlan";
import USN_AllPlansMarketView from "../Components/USN_AllPlansMarketView";

export const Context = createContext<any>({});

const PlanInsight = () => {
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);
  const isTX = currentLocation?.state_id === "TX";
  const myProvider = useSelector(selectMyProvider);
  const [allProvider, setAllProvider] = useState([]);

  const getAllProvider = async () => {
    const res = await fetchLoginProviderList();
    if (res.status !== 1) {
      setAllProvider([]);
      errornotify(res.message || "Error");
      // return;
    }
    setAllProvider(res.response);
  };

  useEffect(() => {
    getAllProvider();
  }, [currentLocation]);

  return (
    <div className="page-content">
      <Helmet>
        <title>PowerRateIndex</title>
      </Helmet>
      <Context.Provider
        value={{
          provider: myProvider || {
            provider_id: "",
            provider_name: "",
            time: "",
          },
          allProvider,
          setAllProvider,
        }}
      >
        {isTX ? <TxComps /> : <UsnComps />}
      </Context.Provider>
    </div>
  );
};
const TxComps = () => {
  return (
    <Container>
      <LoginMarketSummary />
      <LoginTopPlan />
    </Container>
  );
};

const UsnComps = () => {
  return (
    <Container>
      <USN_AllPlansMarketView />
    </Container>
  );
};

export default PlanInsight;
