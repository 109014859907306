import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import "../styles/market.css";
import { Context } from "../pages/LoginMarket";
import { Card, CardBody, CardHeader, Label } from "reactstrap";
import { DataWithColorSeriesTypes, DataPointTypes } from "../types";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  fetchUSNAvgPriceTrend,
  fetchUSNMarketAvgPrice,
  fetchUSNStateProviders,
} from "../api/usnMarket";
import { selectUtility } from "../store/utility/selector";
import DataTable from "react-data-table-component";
import { PriceComparisionChart } from "./Charts/PriceComparisionChart";
import { Typography } from "@mui/material";
import { PriceBarChart } from "./Charts/PriceBarChart";
// import Datepicker from "./Datepicker";

interface searchOptionObj {
  value: any;
  label: any;
}

const today = moment().format("YYYY-MM-DD");

const calDate = (val: string) => {
  return moment(val, "YYYY-MM-DD").subtract(30, "days").format("YYYY-MM-DD");
};

const toChartsData = (inputData: any, outputData: any) => {
  if (inputData) {
    return inputData.map((item: any) => {
      if (item.date && +item.average_price !== 0) {
        return outputData.push({ x: item.date, y: +item.average_price });
      }
      return null;
    });
  } else {
    return (outputData = []);
  }
};

const MarketAvgRateUsn = () => {
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);
  const { provider, allProvider } = useContext(Context);
  const [compareProvider, setCompareProvider] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [filterValue, setFilterValue] = useState({
    date: `${calDate(today)}&${today}`,
  });
  const [data, setData] = useState<DataWithColorSeriesTypes[]>([]);
  const [last12MonthData, setLast12MonthData] = useState<any>([]);
  const [providerList, setProviderList] = useState(["", provider.provider_id]);
  const [searchProviderList, setSearchProviderList] = useState<
    Array<searchOptionObj>
  >([]);
  const currentUtility = useSelector(selectUtility);

  const getAvgPrice = async () => {
    setIsLoading(true);

    const promise = providerList.map((item) => {
      return fetchUSNMarketAvgPrice(
        currentLocation?.state_id,
        currentUtility.utility_id,
        filterValue.date,
        "1",
        +item || ""
      );
    });
    const res = await Promise.all(promise);
    const resData: any = res.map((item, index) => {
      if (item.status === 1) {
        return item.response.average_price;
      } else {
        return null;
      }
    });

    let avgData: DataPointTypes[] = [];
    let providerData: DataPointTypes[] = [];
    toChartsData(resData[0], avgData);
    toChartsData(resData[1], providerData);

    setData([
      {
        id: `${provider ? provider.provider_name : ""}`,
        data: providerData,
        color: "hsl(207,54.0%,46.9%)",
      },
      {
        id: "Market Average Rate",
        data: avgData,
        color: "hsl(118,40.6%,48.8%)",
      },
    ]);
    setIsLoading(false);
  };

  const getLast12MonthsData = async () => {
    const res = await fetchUSNAvgPriceTrend(
      currentLocation?.state_id,
      currentUtility.utility_id,
      "3"
    );

    if (res?.status === 1) {
      const { average_price } = res?.response || {};

      setLast12MonthData(
        average_price?.map((item: any) => ({
          date: moment(item.date, "MM/DD/YYYY").format("YYYY-MM-DD"),
          value: Number(item.average_price),
        })) || []
      );
    }
  };

  useEffect(() => {
    if (+provider.provider_id && allProvider.length && currentUtility) {
      getAvgPrice();
      getLast12MonthsData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue, providerList, currentUtility]);

  useEffect(() => {
    const getStateProviders = () => {
      fetchUSNStateProviders(
        currentLocation?.state_id,
        "1",
        currentUtility?.utility_id
      ).then((res) => {
        const formattedList =
          res?.response?.map((item: any) => ({
            value: item.provider_id,
            label: item.provider_name,
          })) || [];
        setSearchProviderList(formattedList);
      });
    };

    if (currentLocation && currentUtility) {
      getStateProviders();
    }
  }, [currentLocation, currentUtility]);

  useEffect(() => {
    if (+provider.provider_id && allProvider.length) {
      setProviderList(["", provider.provider_id]);
      // setCompareName(allProvider[0]?.provider_name)
    } else {
      return;
    }
  }, [provider, allProvider]);

  useEffect(() => {
    if (compareProvider) {
      changeCompare(compareProvider);
    } else {
      setData((datas) => {
        let arr = datas.slice(0);
        arr.splice(0, 1);
        return arr;
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareProvider, filterValue]);

  const changeDate = (val: any) => {
    setFilterValue({
      ...filterValue,
      date: `${calDate(moment(val).format("YYYY-MM-DD"))}&${moment(val).format(
        "YYYY-MM-DD"
      )}`,
    });
  };

  const compareFun = (datas: any, val: any, id: any) => {
    let arr = datas.slice(0);
    if (arr.length === 2) {
      arr.unshift({
        id: `${id.label ? id.label : ""}`,
        data: val,
        color: "hsl(359,79.5%,49.8%)",
      });
    } else {
      arr = datas.map((item: any, index: any) => {
        if (index === 0) {
          return {
            id: `${id.label ? id.label : ""}`,
            data: val,
            color: "hsl(359,79.5%,49.8%)",
          };
        } else {
          return item;
        }
      });
    }
    return arr;
  };

  const changeCompare = async (id: any) => {
    setIsLoading(true);

    const avg_res = await fetchUSNMarketAvgPrice(
      currentLocation?.state_id,
      currentUtility.utility_id,
      filterValue.date,
      "1",
      id.value
    );
    if (avg_res.status === 1) {
      let avgCompare: any = [];
      toChartsData(avg_res.response.average_price, avgCompare);
      setData((datas) => compareFun(datas, avgCompare, id));
    } else {
      // errornotify(avg_res.message || `Error trend[average]`);
    }

    setIsLoading(false);
  };

  return (
    <Card style={{ minHeight: "600px" }}>
      <CardHeader>
        <h4 className="card-title mb-0 flex-grow-1">Market Average Rate</h4>
        {currentLocation && currentUtility && (
          <Typography
            variant="subtitle2"
            className="text-muted"
          >{`${currentLocation.state_name} - ${currentUtility.utility_name}`}</Typography>
        )}
      </CardHeader>
      <CardBody>
        <React.Fragment>
          <PriceBarChart
            title="Average Rate (Last 12 months)"
            data={last12MonthData}
            isLoading={isLoading}
          />

          <div>
            <Label
              htmlFor="aria-example-input"
              className="form-label text-muted mb-0"
              style={{ fontWeight: "400" }}
            >
              Compare with
            </Label>
            <div style={{ width: "240px" }}>
              <Select
                defaultValue={searchProviderList[0]}
                value={compareProvider}
                isClearable
                onChange={(val) => setCompareProvider(val)}
                options={searchProviderList}
              />
            </div>
            {/* <Datepicker
              handleChangeDate={(val: any) => changeDate(val)}
              disabled={isLoading}
            /> */}
          </div>
          <PriceComparisionChart
            title="Average Rate (Last 30 days)"
            data={data}
            isLoading={isLoading}
          />
          {!isLoading && <TrendTable data={data} />}
        </React.Fragment>
      </CardBody>
    </Card>
  );
};

const TrendTable = ({ data }: { data: any }) => {
  const formatTableData = (data: any) => {
    return data.map((item: any) => {
      const formatted: any = { id: item.id };
      item.data.forEach((entry: any) => {
        formatted[entry.x] = entry.y.toString();
      });
      return formatted;
    });
  };

  const tableData = formatTableData(data) || [];

  const frozenColumns = [
    {
      name: <span className="font-weight-bold fs-14">Rate Type</span>,
      selector: (row: any) => row?.id,
    },
  ];

  const scrollableColumns = Object.keys(tableData[0] || {})
    .filter((key) => key !== "id")
    .map((dateKey) => ({
      name: dateKey,
      selector: (row: any) => `${row[dateKey]}¢`,
    }));

  if (tableData.length === 0) return null;

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F2F2F2",
      },
    },
  };
  return (
    <div className="combined-table">
      <div className="fronzen-table">
        <DataTable
          columns={frozenColumns}
          data={tableData}
          paginationPerPage={10}
          customStyles={{
            cells: { style: { backgroundColor: "#fafafa" } },
            ...customStyles,
          }}
          pagination={false}
        />
      </div>
      <div className="scrollable-table">
        <DataTable
          columns={scrollableColumns}
          data={tableData}
          paginationPerPage={10}
          customStyles={customStyles}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default MarketAvgRateUsn;
