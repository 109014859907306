import { Chip } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { menu } from "./HeaderMenuData";
import { useDispatch } from "react-redux";
import { changeLocation } from "../store/actions";

const HeaderMenu = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <div className="show-xs">
        <Dropdown
          isOpen={showMenu}
          toggle={() => setShowMenu(!showMenu)}
          className="ms-sm-3 header-item topbar-user"
        >
          <DropdownToggle tag="button" type="button" className="btn">
            <span className="hamburger-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <RenderMenu
              isDropdown={true}
              handleCloseDropdown={() => setShowMenu(false)}
            />
          </DropdownMenu>
        </Dropdown>
      </div>
      <div className="hide-xs">
        <RenderMenu handleCloseDropdown={() => setShowMenu(false)} />
      </div>
    </>
  );
};

const RenderMenu = ({ isDropdown, handleCloseDropdown }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(menu[0].key);
  const [openedMenu, setOpenedMenu] = useState("");

  useEffect(() => {
    const pathname = location.pathname || "";
    const currentMenu = menu.reduce((found, item) => {
      if (found) return found;
      if (item.path === pathname) return item;
      if (item.subMenu) {
        return (
          item.subMenu.find((subItem) => subItem.path === pathname) || found
        );
      }
      return found;
    }, null);

    if (currentMenu) {
      setCurrentPage(currentMenu.key);
    } else {
      setCurrentPage("");
    }
  }, [location.pathname]);

  return (
    <ul
      id="header-menu"
      className="d-md-flex align-items-center mb-0 list-unstyled"
    >
      {menu.map((item) => {
        if (item.subMenu) {
          if (isDropdown) {
            return (
              <>
                <span className="text-start ms-3 text-muted fs-12">
                  {item.name}
                </span>
                {item.subMenu.map((subItem) => (
                  <Link
                    to={subItem.path}
                    className={`d-flex align-items-center gap-1 nav-link menu-link lh-1 ${
                      currentPage === subItem.key ? "active" : "text-black"
                    }`}
                    onClick={() => handleCloseDropdown()}
                  >
                    <span className="d-flex align-items-center gap-1">
                      {subItem.icon && (
                        <i
                          className={`${subItem.icon} ${
                            currentPage === subItem.key ? "" : "text-black-50"
                          }`}
                        ></i>
                      )}
                      {subItem.name}
                    </span>
                    {subItem.isTxOnly && (
                      <Chip label="Texas" size="small" sx={{ fontSize: 10 }} />
                    )}
                  </Link>
                ))}
              </>
            );
          } else {
            return (
              <Dropdown
                isOpen={openedMenu === item.key}
                toggle={() => {
                  setOpenedMenu(openedMenu ? "" : item.key);
                }}
                key={item.key}
                className="header-item"
              >
                <DropdownToggle
                  tag="button"
                  type="button"
                  className="btn dropdown-btn"
                >
                  <span className="d-flex align-items-center">
                    {item.icon && (
                      <i
                        className={`${item.icon} ${
                          currentPage === item.key ? "" : "text-black-50"
                        }`}
                      ></i>
                    )}
                    <span className="text-start ms-1">{item.name}</span>
                    <i className="ri-arrow-down-s-line ms-1" />
                  </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  {item.subMenu.map((subItem) => (
                    <DropdownItem
                      key={subItem.key}
                      onClick={() => {
                        if (subItem.isTxOnly) {
                          dispatch(
                            changeLocation({
                              state_id: "TX",
                              state_name: "Texas",
                            })
                          );
                        }
                        history.push(subItem.path);
                        handleCloseDropdown();
                      }}
                      className="p-0"
                    >
                      <span
                        className={`d-flex align-items-center gap-2 justify-content-between nav-link lh-1 ${
                          currentPage === subItem.key ? "active" : "text-black"
                        }`}
                      >
                        <span className="d-flex align-items-center gap-1">
                          {subItem.icon && (
                            <i
                              className={`${subItem.icon} ${
                                currentPage === subItem.key
                                  ? ""
                                  : "text-black-50"
                              }`}
                            ></i>
                          )}
                          {subItem.name}
                        </span>
                        {subItem.isTxOnly && (
                          <Chip
                            label="Texas"
                            size="small"
                            sx={{ fontSize: 10 }}
                          />
                        )}
                      </span>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            );
          }
        }
        return (
          <li className="nav-item" key={item.key}>
            <Link
              to={item.path}
              className={`d-flex align-items-center gap-1 nav-link menu-link lh-1 ${
                currentPage === item.key ? "active" : "text-black"
              }`}
              onClick={() => handleCloseDropdown()}
            >
              {item.icon && (
                <i
                  className={`${item.icon} ${
                    currentPage === item.key ? "" : "text-black-50"
                  }`}
                ></i>
              )}
              {item.name}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default HeaderMenu;
