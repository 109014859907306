import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { useAuth } from "../Components/Hooks/UserHooks";
//import images
import logoDark from "../assets/images/logo-dark.png";
import logoLight from "../assets/images/logo-light.png";
import ProfileDropdown from "../Components/Common/ProfileDropdown";
import HeaderMenu from "./HeaderMenu";
// import LocationDropdown from "../Components/Common/LocationDropdown";
// import UtilityDropdown from "../Components/Common/UtilityDropdown";

const Header = ({ headerClass }) => {
  const { userProfile } = useAuth();

  const toogleMenuBtn = () => {
    var windowSize = document.documentElement.clientWidth;

    if (windowSize > 767)
      document.querySelector(".hamburger-icon").classList.toggle("open");

    //For collapse horizontal menu
    if (document.documentElement.getAttribute("data-layout") === "horizontal") {
      document.body.classList.contains("menu")
        ? document.body.classList.remove("menu")
        : document.body.classList.add("menu");
    }

    //For collapse vertical menu
    if (document.documentElement.getAttribute("data-layout") === "vertical") {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "sm"
          ? document.documentElement.setAttribute("data-sidebar-size", "")
          : document.documentElement.setAttribute("data-sidebar-size", "sm");
      } else if (windowSize > 1025) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "lg"
          ? document.documentElement.setAttribute("data-sidebar-size", "sm")
          : document.documentElement.setAttribute("data-sidebar-size", "lg");
      } else if (windowSize <= 767) {
        document.body.classList.add("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }
    }

    //Two column menu
    if (document.documentElement.getAttribute("data-layout") === "twocolumn") {
      document.body.classList.contains("twocolumn-panel")
        ? document.body.classList.remove("twocolumn-panel")
        : document.body.classList.add("twocolumn-panel");
    }
  };

  return (
    <React.Fragment>
      <header id="page-topbar" className={headerClass}>
        <div className="navbar-header">
          <div className="navbar-brand-box horizontal-logo">
            <Link to="/" className="logo logo-dark">
              <span className="logo-lg">
                <img src={logoDark} alt="" />
              </span>
            </Link>

            <Link to="/" className="logo logo-light">
              <span className="logo-lg">
                <img src={logoLight} alt="" />
              </span>
            </Link>
          </div>
          <div
            className="d-flex flex-1 justify-content-between align-items-center"
            style={{ width: "100%" }}
          >
            {/* {userProfile && (
              <button
                onClick={toogleMenuBtn}
                type="button"
                className="btn btn-sm px-3 fs-17 header-item vertical-menu-btn topnav-hamburger"
                id="topnav-hamburger-icon"
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>
            )} */}
            {userProfile ? <HeaderMenu /> : <div />}
            {/* <div className="d-flex">
              <LocationDropdown />
              <UtilityDropdown />
            </div> */}
            <div className="d-flex align-items-center">
              {userProfile ? (
                <ProfileDropdown />
              ) : (
                <Button id="login-btn" color="secondary" href="/login" className="ms-4">
                  Login
                </Button>
              )}
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
