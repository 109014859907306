import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useLocation } from "react-router-dom";

//import Components
import Header from "./Header";
// import Sidebar from "./Sidebar";
import Footer from './Footer';
// import UniversalFooter from "universal-footer";
import RightSidebar from "../Components/Common/RightSidebar";

//import actions
import {
  changeLayout,
  changeSidebarTheme,
  changeLayoutMode,
  changeLayoutWidth,
  changeLayoutPosition,
  changeTopbarTheme,
  changeLeftsidebarSizeType,
  changeLeftsidebarViewType,
  changeSidebarImageType,
} from "../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { EmailModal } from "../Components/ProfileModal";
import { ChangePassword } from "../Components/ChangePassword";
// import { useAuth } from "../Components/Hooks/UserHooks";
import { ChangeRep } from "../Components/ChangeRep";
import { SuccessSnackbar } from "../Components/SuccessSnackbar";
import { menu } from "./HeaderMenuData";
import { Col, Container, Row } from "reactstrap";
import StateUtilitySidebar from "./StateUtilitySidebar";

const Layout = (props) => {
  const location = useLocation();
  const [headerClass, setHeaderClass] = useState("");
  const [showSidebar, setShowSidebar] = useState(false);
  // const { userProfile } = useAuth();

  const dispatch = useDispatch();
  const {
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
  } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
    leftSidebarType: state.Layout.leftSidebarType,
    layoutModeType: state.Layout.layoutModeType,
    layoutWidthType: state.Layout.layoutWidthType,
    layoutPositionType: state.Layout.layoutPositionType,
    topbarThemeType: state.Layout.topbarThemeType,
    leftsidbarSizeType: state.Layout.leftsidbarSizeType,
    leftSidebarViewType: state.Layout.leftSidebarViewType,
    leftSidebarImageType: state.Layout.leftSidebarImageType,
  }));

  /*
    layout settings
    */
  useEffect(() => {
    if (
      layoutType ||
      leftSidebarType ||
      layoutModeType ||
      layoutWidthType ||
      layoutPositionType ||
      topbarThemeType ||
      leftsidbarSizeType ||
      leftSidebarViewType ||
      leftSidebarImageType
    ) {
      dispatch(changeLeftsidebarViewType(leftSidebarViewType));
      dispatch(changeLeftsidebarSizeType(leftsidbarSizeType));
      dispatch(changeSidebarTheme(leftSidebarType));
      dispatch(changeLayoutMode(layoutModeType));
      dispatch(changeLayoutWidth(layoutWidthType));
      dispatch(changeLayoutPosition(layoutPositionType));
      dispatch(changeTopbarTheme(topbarThemeType));
      dispatch(changeLayout(layoutType));
      dispatch(changeSidebarImageType(leftSidebarImageType));
    }
  }, [
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
    dispatch,
  ]);
  /*
    call dark/light mode
    */
  const onChangeLayoutMode = (value) => {
    if (changeLayoutMode) {
      dispatch(changeLayoutMode(value));
    }
  };

  // class add remove in header
  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });
  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setHeaderClass("topbar-shadow");
    } else {
      setHeaderClass("");
    }
  }

  useEffect(() => {
    const pathname = location.pathname || "";
    const currentMenu = menu.reduce((found, item) => {
      if (found) return found;
      if (item.path === pathname) return item;
      if (item.subMenu) {
        return (
          item.subMenu.find((subItem) => subItem.path === pathname) || found
        );
      }
      return found;
    }, null);

    setShowSidebar(currentMenu?.hasSidebar);
  }, [location.pathname]);

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header
          headerClass={headerClass}
          layoutModeType={layoutModeType}
          onChangeLayoutMode={onChangeLayoutMode}
        />
        {/* {userProfile && <Sidebar layoutType={layoutType} />} */}
        <div className="main-content">
          {showSidebar ? (
            <Container style={{ maxWidth: "1440px" }}>
              <Row>
                <Col md={2}>
                  <StateUtilitySidebar />
                </Col>
                <Col md={10}>{props.children}</Col>
              </Row>
            </Container>
          ) : (
            props.children
          )}
          <Footer />
          {/* <UniversalFooter
            defaultMenu={[
              {
                name: "Products",
                items: [
                  {
                    name: "PowerLego",
                    url: "https://www.powerlego.com/",
                  },
                  {
                    name: "EIQhome",
                    url: "https://eiqhome.com/",
                  },
                  {
                    name: "EIQShopping",
                    url: "https://eiqshopping.com/",
                  },
                  {
                    name: "ESIID.io",
                    url: "https://esiid.io/",
                  },
                  {
                    name: "BillReader",
                    url: "https://www.billreader.com/",
                  },
                ],
              },
              {
                name: "Support",
                items: [
                  {
                    name: "Contact Us",
                    url: "https://www.powerrateindex.org/contact-us",
                  },
                ],
              },
            ]}
          /> */}
        </div>
      </div>
      <RightSidebar />
      <EmailModal />
      <ChangePassword />
      <ChangeRep />
      <SuccessSnackbar />
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.object,
};

export default withRouter(Layout);
